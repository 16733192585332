<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>
    <mat-card>
        <mat-card-content>
            <div class="d-flex align-items-end mb-3">
                <h1 class="fs-3 fw-light mb-0">{{ titlePage }}</h1>
                <button mat-icon-button color="primary" class="btn-sm ms-auto" (click)="toggleFilters = !toggleFilters">
                    <mat-icon>tune</mat-icon>
                </button>
                <button mat-flat-button color="primary" class="ms-3" [routerLink]="['/dio/novo']" *ngIf="showCreateDio()">NOVO DIO</button>
            </div>
            <!-- FILTROS -->
            <ng-collapse [toggle]="toggleFilters">
                <div class="border-top px-1 py-3">
                    <div class="row align-items-end">
                        <mat-form-field class="col-12" [ngClass]="showFields.status ? 'col-md-2' : 'col-md-4'">
                            <mat-label>SEI</mat-label>
                            <input matInput name="sei" #sei="ngModel" [(ngModel)]="filters.sei">
                        </mat-form-field>
                        <mat-form-field class="col-12" [ngClass]="showFields.status ? 'col-md-2' : 'col-md-4'">
                            <mat-label>Número</mat-label>
                            <input matInput name="numero" #numero="ngModel" [(ngModel)]="filters.numero">
                        </mat-form-field>
                        <mat-form-field class="col-12 col-md-4" *ngIf="showFields.status">
                            <mat-label>Status de Execução</mat-label>
                            <mat-select name="statusExecucao" #grupo="ngModel" [(ngModel)]="filters.statusExecucao">
                                <mat-option *ngFor="let status of statusExecucao" [value]="status.value">
                                    {{ status.label }}
                                </mat-option>
                            </mat-select>
                            <button *ngIf="filters.statusExecucao" matSuffix mat-icon-button aria-label="Clear"
                                (click)="filters.statusExecucao=undefined; $event.stopPropagation()">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                        <div class="col-12 col-md-4">
                            <ng-select name="orgao" [items]="orgaos" bindValue="id" placeholder="Orgão" [searchFn]="searchFn" 
                                [loading]="loading.filter" [(ngModel)]="filters.orgao" [compareWith]="compareWith"
                                [disabled]="loading.filter || orgao !== null && orgao !== undefined">
                                <ng-template ng-label-tmp let-item="item">
                                    {{ item.nome }}
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                    {{ item.nome }}
                                </ng-template>
                            </ng-select>
                        </div>
                        <ng-container *ngIf="showFields.BenConEqui">
                            <mat-form-field class="col-12 col-md-4" *ngIf="currentRole !== 'CONTRATADO_ADM'">
                                <mat-label>Beneficiaria/CNPJ</mat-label>
                                <input matInput name="beneficiaria" #beneficiaria="ngModel" [(ngModel)]="filters.beneficiaria">
                            </mat-form-field>
                            <div class="col-12 col-md-4">
                                <ng-select name="contrato" [items]="contratos" bindValue="id" bindLabel="nome" placeholder="Contrato"
                                    [loading]="loading.filter" [disabled]="loading.filter" [(ngModel)]="filters.contrato" 
                                    [searchFn]="searchFn" [compareWith]="compareWith">
                                </ng-select>
                            </div>
                            <div class="col-12 col-md-4">
                                <ng-select name="alocacao" [items]="alocacoes" bindValue="id" placeholder="Equipamento" 
                                    [loading]="loading.filter" [disabled]="loading.filter" [(ngModel)]="filters.alocacao"
                                    [searchFn]="searchEquipamento" [compareWith]="compareWith">
                                        <ng-template ng-option-tmp let-item="item" ng-label-tmp>
                                            {{ item.codigo }} / {{ item.equipamento?.placa }}
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                            {{ item.codigo || item.equipamento.placa}}
                                        </ng-template>
                                </ng-select>
                            </div>
                        </ng-container>
                        <mat-form-field class="col-12 col-md-4">
                            <mat-label>Funcionário</mat-label>
                            <input matInput name="funcionario" #executorLocal="ngModel" [(ngModel)]="filters.funcionario">
                        </mat-form-field>
                        <div class="col-12 col-md-4">
                            <ng-select name="demandante" [items]="demandantes" bindValue="id" placeholder="Demandante"
                                [loading]="loading.filter" [disabled]="loading.filter" [(ngModel)]="filters.demandante"
                                [searchFn]="searchFn" [compareWith]="compareWith">
                                <ng-template ng-label-tmp let-item="item">
                                    {{ item.nome }}
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                    {{ item.nome }}
                                </ng-template>
                            </ng-select>
                        </div>
                        <div class="col-12 col-md-4">
                            <ng-select name="condutor" [items]="condutores" bindValue="id" placeholder="Condutor" 
                                [loading]="loading.filter" [disabled]="loading.filter" [(ngModel)]="filters.condutor"
                                [searchFn]="searchCondutor" [compareWith]="compareWith">
                                <ng-template ng-label-tmp let-item="item">
                                    {{ item.firstName }} {{ item.lastName }}
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                    {{ item.firstName }} {{ item.lastName }}
                                </ng-template>
                            </ng-select>
                        </div>
                        <div class="col-12 col-md-4">
                            <span class="fw-bold fs-7 opacity-50">DATA DE CRIAÇÃO</span>
                            <div class="d-flex">
                                <mat-form-field class="me-2">
                                    <mat-label>Inicio</mat-label>
                                    <input matInput [matDatepicker]="startCreatedAt" name="inicio" #inicio="ngModel"
                                        autocomplete="off" [(ngModel)]="filters.startCreatedAt">
                                    <mat-datepicker-toggle matIconSuffix [for]="startCreatedAt"></mat-datepicker-toggle>
                                    <mat-datepicker #startCreatedAt></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Fim</mat-label>
                                    <input matInput [matDatepicker]="endCreatedAt" name="fim" #fim="ngModel" autocomplete="off"
                                        [(ngModel)]="filters.endCreatedAt">
                                    <mat-datepicker-toggle matIconSuffix [for]="endCreatedAt"></mat-datepicker-toggle>
                                    <mat-datepicker #endCreatedAt></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <span class="fw-bold fs-7 opacity-50">DATA DA OPERACAO</span>
                            <div class="d-flex">
                                <mat-form-field class="me-2">
                                    <mat-label>Inicio</mat-label>
                                    <input matInput [matDatepicker]="startDataPeriodoInicioOperacao" name="inicio" #inicio="ngModel"
                                        autocomplete="off" [(ngModel)]="filters.startDataPeriodoInicioOperacao">
                                    <mat-datepicker-toggle matIconSuffix
                                        [for]="startDataPeriodoInicioOperacao"></mat-datepicker-toggle>
                                    <mat-datepicker #startDataPeriodoInicioOperacao></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Fim</mat-label>
                                    <input matInput [matDatepicker]="endDataPeriodoInicioOperacao" name="fim" #fim="ngModel"
                                        autocomplete="off" [(ngModel)]="filters.endDataPeriodoInicioOperacao">
                                    <mat-datepicker-toggle matIconSuffix [for]="endDataPeriodoInicioOperacao"></mat-datepicker-toggle>
                                    <mat-datepicker #endDataPeriodoInicioOperacao></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>                        
                        <div class="col-12 col-md-auto ms-auto">
                            <button mat-flat-button color="secondary" class="w-100" (click)="filters = {}">LIMPAR</button>
                        </div>
                        <div class="col-12 col-md-2">
                            <button mat-flat-button color="accent" class="w-100" (click)="search()" [disabled]="loading.filter" >FILTRAR</button>
                        </div>
                    </div>
                </div>
            </ng-collapse>

            <!-- CARDS -->
            <mat-spinner class="mx-auto my-5" *ngIf="loading.main"></mat-spinner>
            <ng-container  *ngIf="!loading.main && dataSource && dataSource.length">
                <mat-card class="shadow-none border mb-3 action" *ngFor="let dio of dataSource; trackBy: trackByFn">
                    <mat-card-content class="pb-0">
                        <div class="d-flex align-items-center justify-content-between">
                            <div [routerLink]="['/dio', dio.id, 'visualizar']">
                                <strong>{{ dio.numero }}</strong>
                                <span *ngIf="dio.servicoNome"> - {{ dio.servicoNome }}</span>
                                <mat-icon matListItemIcon class="me-2" matTooltip="Detalhar" style="color: grey; margin-left: 5px; padding-top: 3px;">visibility</mat-icon>                        
                            </div>
                            <div>
                              <span class="status-badge {{getBadgeColor(dio.statusExecucao)}}">
                                {{ (dio.statusExecucao) | translate }}
                              </span>
                              <span *ngIf="dio.alreadyTenDays" class="status-badge bg-danger text-white mx-2 lh-lg">
                                Disponibilizada a mais de 10 dias
                              </span>
                            </div>
                        </div>
                        <hr class="border-gray-400 my-2">
                        <div class="row">
                            <div class="col-12 col-md-11 mb-11" (click)="visualizarDio(dio)">
                                <div class="row">
                                    <div class="col-12 col-md-4 mb-4">
                                        <span class="fw-bold fs-7 opacity-50">EQUIPAMENTO</span>
                                        <div class="lh-sm">{{ dio.equipamentoNome || '---' }}</div>
                                    </div>
                                    <div class="col-12 col-md-4 mb-4">
                                        <span class="fw-bold fs-7 opacity-50">LOCAL APRESENTAÇÃO</span>
                                        <div class="lh-sm">{{ dio.localApresentacaoNome || '---' }}</div>
                                        <div class="lh-sm fs-7 text-gray">{{ dio.orgaoNome | uppercase}}</div>
                                    </div>
                                    <div class="col-12 col-md-4 mb-4">
                                        <span class="fw-bold fs-7 opacity-50">DEMANDANTE</span>
                                        <div class="d-flex">
                                            <span class="lh-sm">
                                                {{ dio.demandanteNome || '---' }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-4 mb-4">
                                        <span class="fw-bold fs-7 opacity-50">INICIO OPERAÇÃO</span>
                                        <div class="lh-sm">{{ dio.dataPeriodoInicioOperacao ? (dio.dataPeriodoInicioOperacao | date:'dd/MM/yyyy') : '---'}}</div>
                                    </div>
                                    <div class="col-12 col-md-4 mb-4">
                                        <span class="fw-bold fs-7 opacity-50">CONTRATO</span>
                                        <div class="lh-sm">{{ dio.contratoNome || '---' }}</div>
                                        <div class="lh-sm fs-7 text-gray">{{ dio.beneficiariaNome }}</div>
                                    </div>
                                    <div class="col-12 col-md-4 mb-4">
                                        <span class="fw-bold fs-7 opacity-50">CONDUTOR</span>
                                        <div class="lh-sm">{{ dio.condutor || '---' }}</div>
                                    </div>
                                </div>
                                <div class="row" *ngf="false">
                                    <div class="col-12 col-md-4 mb-4">
                                        <span class="fw-bold fs-7 opacity-50">TOTAL HORA DISPONÍVEL</span>
                                        <div class="lh-sm">08:00</div>
                                    </div>
                                    <div class="col-12 col-md-4 mb-4">
                                        <span class="fw-bold fs-7 opacity-50">TOTAL HORA TRABALHADA</span>
                                        <div class="lh-sm">08:00</div>
                                    </div>
                                    <div class="col-12 col-md-4 mb-4">
                                        <span class="fw-bold fs-7 opacity-50">TOTAL KM</span>
                                        <div class="lh-sm">117 KM</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-1 mb-1" *ngIf="podeExibirBotoesRapidosDios(dio)">
                                <mat-list class="d-flex flex-column d-print-none">                                    
                                    <mat-list-item class="me-1 mt-auto mb-4 color_green" (click)="aproveDio(dio)">
                                        <div *ngIf="podeAprovarDio(dio)">
                                            <mat-icon matListItemIcon class="me-2" matTooltip="Aprovar" style="color: green; transform: scale(1.1);">check_circle</mat-icon>
                                        </div>
                                    </mat-list-item>
                                    <mat-list-item class="me-2 mt-auto mb-4" (click)="cancelDio(dio)">
                                        <div *ngIf="podeCancelarDio(dio)">
                                            <mat-icon matListItemIcon class="me-2" matTooltip="Cancelar" style="color: red; transform: scale(1.1);">cancel</mat-icon>
                                        </div>
                                    </mat-list-item>
                                    <mat-list-item class="me-2 mt-auto mb-4" (click)="openMap(dio)">
                                        <div *ngIf="podeExibirBotaoMapa(dio)">
                                            <mat-icon matListItemIcon class="me-2" matTooltip="Ver Mapa" style="color: rgb(39, 164, 247); transform: scale(1.1);">maps</mat-icon>             
                                        </div>
                                    </mat-list-item>
                                </mat-list>
                            </div>
                        </div>                 
                    </mat-card-content>
                </mat-card>
            </ng-container>
            <div class="text-muted text-center pt-4 pb-3 border-top"
                *ngIf="!loading.main && (!dataSource || !dataSource.length)">
                Nenhuma informação encontrada
            </div>
            <!-- PAGINATION -->
            <mat-paginator *ngIf="pagination.total && pagination.pageSize"
                (page)="search({ current: $event.pageIndex, pageSize: $event.pageSize })" 
                [length]="pagination.total" [pageSize]="pagination.pageSize" [pageSizeOptions]="[10, 20, 30, 50]" [showFirstLastButtons]="true"
            ></mat-paginator>
        </mat-card-content>
    </mat-card>
</div>
