import { Injectable } from '@angular/core';
import { Relatorio, RelatorioFilter } from './relatorio.model';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { GenericCRUDService } from '../shared/services';
import { Data, Pagination } from '../shared/models';
import { Dio, DioDetalhesExecucao } from '../dio/dio.model';
import { VBBFilters } from './valor-bruto-beneficiario/valor-bruto-beneficiario.component';
import { GenerateTablePagination } from '../shared/components';

@Injectable({
  providedIn: 'root'
})
export class RelatorioService extends GenericCRUDService {

  constructor(http: HttpClient) {
    super(http);
    this.url = `/relatorios`;
  }

  getRelatorioDiosNaoExecutadas(filters?: any): Observable<Data<Dio>> {
    const params = new URLSearchParams();
    if (filters)
      for (const key in filters)
          params.append(key, filters[key]);

    return this.http.get<Data<Dio>>(`${this.url}/dios-nao-executados?${params}`);
  }

  getRelatorioDiosAferidas(filters?: any): Observable<Data<Dio>> {
    const params = new URLSearchParams();
    if (filters)
      for (const key in filters)
          params.append(key, filters[key]);

    return this.http.get<Data<Dio>>(`${this.url}/dios-aferidas?${params}`);
  }

  getRelatorioConsumoPorEquipamento(filters?: any): Observable<Data<DioDetalhesExecucao>> {
    const params = new URLSearchParams();
    if (filters)
      for (const key in filters)
        params.append(key, filters[key]);          

    return this.http.get<any>(`${this.url}/consumo-por-equipamento?${params}`);
  }

  getValoresBrutos(filters: VBBFilters, pagination: GenerateTablePagination): Observable<Data<Dio>> {
    const params = this.newParameters({ ...filters, ...pagination });
    return this.http.get<any>(`${this.url}/dios/valores-brutos?${params}`);
  }

  getValoresBrutosEquipamentos(filters: VBBFilters, pagination: GenerateTablePagination): Observable<Data<Dio>> {
    const params = this.newParameters({ ...filters, ...pagination });
    return this.http.get<any>(`${this.url}/valores-brutos-equipamentos?${params}`);
  }

  getEquipamentosExistentes(page: number, pageSize: number, filters: any): Observable<Data<any>> {
    const params = new URLSearchParams();
    params.append('page', `${page}`);
    params.append('pageSize', `${pageSize}`);

    if (filters)
      for (const key in filters)
          params.append(key, filters[key]);

    return this.http.get<any>(`${this.url}/equipamentos?${params}`);
  }

  downloadArquivoCsvOrPdf(filters: VBBFilters, endpointRelatorio: string, type: string = 'csv') {
    const params = this.newParameters(filters);
    return this.http.get<any>(`${this.url}/${endpointRelatorio}/${type}?${params}`, { observe: 'response', responseType: 'blob' as 'json' });
  }

  getRelatorioAfericao(filters: any): Observable<Data<any>> {
    const params = this.newParameters({ ...filters });
    return this.http.get<any>(`${this.url}/relatorios-afericao?${params}`);
  }

  getRelatorioAprovacao(filters: any): Observable<Data<any>> {
    const params = this.newParameters({ ...filters });
    return this.http.get<any>(`${this.url}/relatorios-aprovacao?${params}`);
  }

  newParameters(filters: any): URLSearchParams {
    const params = new URLSearchParams();

    if (filters) {
      for (const key in filters) {
        if (filters[key] && filters[key] !== '' && filters[key] !== null && filters[key] !== undefined) {
          params.append(key, filters[key]);
        }
      }
    }

    return params;
  }

}