import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, interval, switchMap } from 'rxjs';
import { GenericCRUDService } from '../shared/services';
import { Data, Pagination } from '../shared/models';
import { environment } from 'src/environments/environment';
import { Dio, DioEventos} from './dio.model';
import { formatDataAsString } from '../shared/date-utils';

@Injectable({
    providedIn: 'root'
})
export class DioEventosService extends GenericCRUDService {
    constructor(http: HttpClient) {
        super(http);
        this.url = `/dio-eventos`;
    }

    override getPaginated<T>(page: number, pageSize: number, filters?: any): Observable<Pagination<T>> {
        const params = new URLSearchParams();
        params.append('page', `${page}`);
        params.append('pageSize', `${pageSize}`);
        if (filters)
            for (const key in filters)
                params.append(key, filters[key]);
        return this.http.get<Pagination<T>>(`${this.url}?${params}`);
    }

    findEventosByDIOId(id: string | undefined): Observable<DioEventos[]> {
        return this.http.get<DioEventos[]>(`${this.url}/${id}`);
    }

}
