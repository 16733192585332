export interface GenerateTableColumn {
    header?: string;
    tdClass?: string;
    thClass?: string;
    attr?: string;
    template?: Function;
    click?: {
      disabled?: Function,
      action?: Function
    },
    btnProps?: {
        label: string,
        color?: 'primary' | 'accent' | 'warn',
    }
}

export interface GenerateTablePageEvent { 
    current: number,    
    pageSize: number,
}

export interface GenerateTablePagination {
    total: number,
    current: number,
    pageSize: number
}