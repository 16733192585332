import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Alocacao, AlocacaoService } from '../../..';
import { Breadcrumb, RemoveDialogService } from 'src/app/shared/components';
import { catchErr, convertToDatePicker, showInputError } from 'src/app/shared/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, forkJoin } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Equipamento, EquipamentoGrupo, EquipamentoGrupoService, EquipamentoService } from 'src/app/equipamento';
import { MatButtonModule } from '@angular/material/button';
import { DatePipe } from '@angular/common';
import * as dayjs from 'dayjs';

@Component({
    selector: 'app-form-alocacao',
    templateUrl: './form-alocacao.component.html',
})
export class FormAlocacaoComponent implements OnInit {
    TARIFACAO_NOT_EXISTS_ERROR = 'Nao existe tarifação cadastrada para esse grupo de equipamento!';

    loading: any = { main: true, save: false, equipamentos: true };
    breadcrumb: Breadcrumb[];
    showFormEquip: boolean;

    title: string;
    alocacao = {} as Alocacao;
    newEquipamento: Equipamento;
    equipamentos: Equipamento[];
    grupos: EquipamentoGrupo[];
    codigoNotAllowed: boolean = false;
    showInputError = showInputError;

    constructor(
        private activatedRoute: ActivatedRoute,
        private alocacaoService: AlocacaoService,
        private equipamentoService: EquipamentoService,
        private grupoService: EquipamentoGrupoService,
        private removeDialogService: RemoveDialogService,
        private router: Router,
        private snackBar: MatSnackBar,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.alocacao.id = this.activatedRoute.snapshot.params['idAlocacao'];
        this.alocacao.contrato = { id: this.activatedRoute.snapshot.params['idContrato'] };
        this.title = this.alocacao.id ? 'Editar' : 'Nova';
        this.breadcrumb = [
            { label: 'Início' },
            { label: 'Contratos', route: '/contrato' },
            { label: 'Visualizar contrato', route: `/contrato/${this.alocacao.contrato.id}/visualizar`, queryParams: { tab: 'alocacao' } },
            { label: this.title + ' alocação' },
        ]

        forkJoin([
            this.alocacaoService.getAll<Alocacao>(this.alocacao.contrato.id),
            this.equipamentoService.getAll<Equipamento>(),
        ]).subscribe({
            next: ([alocacoes, equipamentos]) => {
                this.equipamentos = !this.alocacao.id ? equipamentos.data.filter((equip) => !alocacoes.data.some((alocacao) => (alocacao.equipamento.id == equip.id) && alocacao.ativa)) : equipamentos.data;           
                if (this.alocacao.id) {
                    this.loading.main = true;
                    this.alocacaoService.getById<Alocacao>(this.alocacao.id).subscribe({
                        next: (res) => {
                            this.alocacao = {
                                ...res,
                                inicioVigencia: convertToDatePicker(res.inicioVigencia),
                                fimVigencia: convertToDatePicker(res.fimVigencia)
                            };

                            this.setEquipamento(res.equipamento);
                        },
                        error: (err) => catchErr(err, this.snackBar)
                    }).add(() => this.loading.main = false);
                } else {
                    this.loading.main = false;
                }
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.equipamentos = false);
    }

    compareWith(item: Equipamento, selected: Equipamento) {
        return item.id == selected.id
    }

    searchFn(term: any, item: any) {
        term = term.toLocaleLowerCase();
        return item.marca.toLocaleLowerCase().indexOf(term) > -1 ||
            item.modelo.toLocaleLowerCase().indexOf(term) > -1 ||
            item.placa.toLocaleLowerCase().indexOf(term) > -1;
    }

    addEquipamento = ($event: string) => {
        this.showFormEquip = true;
        this.newEquipamento = { placa: $event } as Equipamento;

        this.grupoService.getAll<EquipamentoGrupo>().subscribe({
            next: (res) => this.grupos = res.data,
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.main = false);
    }

    setEquipamento(equipamento: Equipamento) { 
      this.alocacao.equipamento = equipamento;

      if(equipamento?.grupo?.tipo?.sigla) {
        this.getRecommendedCode(equipamento?.grupo?.tipo?.sigla);
      }
    }

    verifyIfCodeAlreadyExists(e: any) {
      this.alocacaoService.getExistsCode(this.alocacao.codigo).subscribe((res) => {
        this.codigoNotAllowed = !!res.result;
      })
    }

    getRecommendedCode(sigla: string) {
      this.alocacaoService.getRecommendedCode(sigla).subscribe((res) => {
        this.alocacao.codigo = res.result;
      })
    }

    save() {
        if (!this.alocacao.equipamento) {
            this.loading.save = true;
            this.equipamentoService.create<Equipamento>(this.newEquipamento).subscribe({
                next: (res) => {
                    this.saveAlocacao(res.id);
                }, error: (err) => {
                    this.loading.save = false;
                    catchErr(err, this.snackBar);
                }
            })
        } else {
            this.saveAlocacao();
        }
    }

    saveAlocacao(equipamentoId?: string) {
        this.loading.save = true;
        let request: Observable<any>;

        const alocacaoToSave = {
            id: this.alocacao.id,
            contrato: this.alocacao.contrato.id,
            equipamento: equipamentoId || this.alocacao.equipamento.id,
            inicioVigencia: dayjs(this.alocacao.inicioVigencia).format('YYYY-MM-DD'),
            fimVigencia: dayjs(this.alocacao.fimVigencia).format('YYYY-MM-DD'),
            codigo: this.alocacao.codigo,
        } as Alocacao;

        if (alocacaoToSave.id) {
            alocacaoToSave.equipamento = alocacaoToSave.equipamento.id;
            request = this.alocacaoService.update<Alocacao>(alocacaoToSave.id, alocacaoToSave)
        } else {
            request = this.alocacaoService.create<Alocacao>(alocacaoToSave)
        }

        request.subscribe({
            next: () => {
                this.router.navigateByUrl(`/contrato/${this.alocacao.contrato.id}/visualizar?tab=alocacao`);
                this.snackBar.open('Alocação salva com sucesso!', '✕', { panelClass: ['success'] });
            }, error: (err) => {
                const message = err?.error?.error;
                if (message === 'alocacao.already.exists') {
                    this.dialog.open(AlocacaoExisteDialog, {
                        data: {
                            oldAlocacao: err.error.alocacao,
                            newAlocacao: alocacaoToSave,
                            contratoId: this.activatedRoute.snapshot.params['idContrato']
                        },
                        width: '100vh'
                    });
                } else {
                    if(err.error.message == this.TARIFACAO_NOT_EXISTS_ERROR) {
                      const equipamento = this.equipamentos.find((equip) => equip.id == this.alocacao.equipamento);
                      this.dialog.open(AlocacaoTarifacaoDialog, {
                        data: {
                          grupo: equipamento?.grupo,
                          contratoId: this.activatedRoute.snapshot.params['idContrato']
                        },
                        width: '55vh'
                      });
                      return;
                    }
                    catchErr(err, this.snackBar)
                }
            }
        }).add(() => this.loading.save = false);
    }

    showRemoveDialog() {
        this.removeDialogService.show('alocação', (dialogRef: MatDialogRef<any>) => {
          this.alocacaoService.removeOne(this.alocacao.id).subscribe({
            next: () => {
              this.snackBar.open('Alocação inativada com sucesso!', '✕', { panelClass: ['success'] });
              this.router.navigateByUrl(`/contrato/${this.alocacao.contrato.id}/visualizar?tab=alocacao`);
              dialogRef.close();
            }, error: (err) => {
              dialogRef.close();
              this.removeDialogService.show('alocação', (dialogRef: MatDialogRef<any>) => {
                this.alocacaoService.forceRemove(this.alocacao.id).subscribe({
                  next: () => {
                    this.snackBar.open('Alocação inativada com sucesso!', '✕', { panelClass: ['success'] });
                    this.router.navigateByUrl(`/contrato/${this.alocacao.contrato.id}/visualizar?tab=alocacao`);
                    dialogRef.close();
                  }, error: (err) => catchErr(err, this.snackBar)
                });
              }, 'Inativar', 'Existe um dio Ativa paara essa alocação, deseja inativar mesmo assim?', 'sim');
            }
          });
        }, 'Inativar', 'Deseja inativar alocação?', 'inativar');
    }
}

@Component({
    standalone: true,
    imports: [MatDialogModule, MatButtonModule, DatePipe],
    template: `
        <h1 mat-dialog-title>Deseja realocar esse equipamento para esse contrato?</h1>
        <div mat-dialog-content>
            <div class="lh-sm mb-3">
                Esse equipamento já tem uma alocação com o mesmo período no seguinte contrato. <br />
                Ao confirmar a realocação desse equipamento a outra alocação será encerrada!
            </div>
            <div class="container-fluid bg-gray-200 pt-2 pb-3 rounded">
                <div class="row">
                    <div class="col-12 mb-2">
                        <span class="fw-bold fs-7 opacity-75">NOME</span>
                        <div class="lh-sm">{{ data.oldAlocacao.contrato.nome }}</div>
                    </div>
                    <div class="col-12 col-md-6 mb-2 mb-md-0">
                        <span class="fw-bold fs-7 opacity-75">INICIO</span>
                        <div class="lh-sm">{{ data.oldAlocacao.inicioVigencia | date:'dd/MM/yyyy' }}</div>
                    </div>
                    <div class="col-12 col-md-6">
                        <span class="fw-bold fs-7 opacity-75">FIM</span>
                        <div class="lh-sm">{{ data.oldAlocacao.fimVigencia | date:'dd/MM/yyyy' }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div mat-dialog-actions class="px-3 pb-3 justify-content-end">
            <button mat-button mat-dialog-close class="px-3">Fechar</button>
            <button mat-flat-button color="accent" (click)="confirm()">Realocar</button>
        </div>`,
})
export class AlocacaoExisteDialog {
    constructor(
        public dialogRef: MatDialogRef<AlocacaoExisteDialog>,
        private alocacaoService: AlocacaoService,
        private router: Router,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: { oldAlocacao: Alocacao,  newAlocacao: Alocacao, contratoId: string },
    ) {}

    confirm() {
        this.alocacaoService.realocacao({
            id: this.data.oldAlocacao.id,
            contrato: this.data.contratoId,
            equipamento: this.data.newAlocacao.equipamento,
            inicioVigencia: this.data.newAlocacao.inicioVigencia,
            fimVigencia: this.data.newAlocacao.fimVigencia,
            codigo: this.data.newAlocacao.codigo,
        } as Alocacao).subscribe({
            next: () => {
                this.dialogRef.close();
                this.snackBar.open('Realocação feita com sucesso!', '✕', { panelClass: ['success'] });
                this.router.navigateByUrl(`/contrato/${this.data.contratoId}/visualizar?tab=alocacao`);
            }, error: (err) => catchErr(err, this.snackBar)
        })
        this.dialogRef.close();
    }
}

@Component({
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, DatePipe],
  template: `
      <h1 mat-dialog-title>Deseja criar uma tarifação para o grupo ({{ data.grupo.nome }})?</h1>
      <div mat-dialog-content>
          <div class="lh-sm mb-3">
          Não foi possível salvar essa alocação porque o grupo {{ data.grupo.nome }} não tem nenhuma tarifação cadastrada, deseja criar uma tarificação para esse grupo de equipamento?
          </div>
      </div>
      <div mat-dialog-actions class="px-3 pb-3 justify-content-end">
          <button mat-button mat-dialog-close class="px-3">Fechar</button>
          <button mat-flat-button color="accent" (click)="confirm()">Sim</button>
      </div>`,
})
export class AlocacaoTarifacaoDialog {
  constructor(
      public dialogRef: MatDialogRef<AlocacaoExisteDialog>,
      @Inject(MAT_DIALOG_DATA) public data: { grupo: EquipamentoGrupo, contratoId: string },
      private router: Router,
  ) {}

  confirm() {
    this.router.navigateByUrl(`/contrato/${this.data.contratoId}/tarifa/nova`);
    this.dialogRef.close();
  }
}
