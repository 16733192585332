import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utcDate'
})
export class UtcDatePipe implements PipeTransform {

  transform(value?: string | Date): string {    
    let date: Date;

    if (typeof value === 'string') {      
      if (value.includes('T')) {        
        date = new Date(value);
      } else if (value.includes('/')) {          
          const [datePart, timePart] = value.split(' ');
          const [day, month, year] = datePart.split('/').map(Number);
          const [hours, minutes, seconds] = timePart.split(':').map(Number);
            
          date = new Date(year, month - 1, day, hours, minutes, seconds);
      } else if (value.includes('-')) {          
          const [datePart, timePart] = value.split(' ');
          const [year, month, day] = datePart.split('-').map(Number);
          const [hours, minutes, seconds] = timePart.split(':').map(Number);
              
          date = new Date(year, month - 1, day, hours, minutes, seconds);
      } else {
        date = new Date(value);
      }     
    } else {
      date = value ? value : new Date();
    }

    if (isNaN(date.getTime())) {
      throw new Error('Invalid date value');
    }

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth retorna 0-11
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

}
